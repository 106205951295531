import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'

import ConfedLogoSM from '../../content/assets/confed-logo-2019-small.svg'

import GatsbyLogo from '../../content/assets/gatsby.svg'
import RctLogo from '../../content/assets/reactlogo.svg'
import GraphQlLogo from '../../content/assets/graphql.svg'
import WebpackLogo from '../../content/assets/webpack.svg'
import NodeLogo from '../../content/assets/node-dot-js.svg'
import NPMLogo from '../../content/assets/npm.svg'
import JavascriptLogo from '../../content/assets/javascript.svg'
import SASSLogo from '../../content/assets/sass.svg'
import CSS3Logo from '../../content/assets/css3.svg'
import HTML5Logo from '../../content/assets/html5.svg'
import GoogAnalyticLogo from '../../content/assets/googleanalytics.svg'


class FooterTemplate extends React.Component {
  render() {

    return (
      //
      <footer className="feet">      
        <TransitionLink
          class="footlogo"
          to="/"
          exit={{ length: 1, delay: .5 }}
          entry={{ delay: 1.5 }}
          >
            <ConfedLogoSM />
        </TransitionLink>

        <ul className="footmenu">        
            <li>
            <TransitionLink
              to="/about/"
              exit={{ length: 1.5, delay: .5 }}
              entry={{ delay: 1 }}
              >
                About
            </TransitionLink> 
            </li>
            <li>
            <TransitionLink
              to="/work/"
              exit={{ length: 1.5, delay: .5 }}
              entry={{ delay: 1 }}
              >
                Work
            </TransitionLink>
            </li>
            <li>
            <TransitionLink
              to="/blog/"
              exit={{ length: 1.5, delay: .5 }}
              entry={{ delay: 1 }}
              >
                Blog
            </TransitionLink>  
            </li>
        </ul>   

        <section>
        <h6>Built With:</h6>
          <GatsbyLogo />
          <RctLogo />
          <GraphQlLogo />
          <WebpackLogo />
          <NodeLogo />
          <NPMLogo />
          <JavascriptLogo />
          <SASSLogo />
          <CSS3Logo />
          <HTML5Logo />
          <GoogAnalyticLogo />
        </section>

        <section className="contactbox">
          <h2>contact info stuff</h2>

          <div>
            <p>hello!</p>
          </div>

        </section>
      </footer>

    )
  }
}
export default FooterTemplate