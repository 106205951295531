import React from "react"
import ConfedLogoXSM from '../../content/assets/confed-logo-2019-xsmall.svg'
import ConfedLogoTY from '../../content/assets/confed-logo-2019-logotype.svg'

import TransitionLink from "gatsby-plugin-transition-link"
import Media from "react-media";



const openNavMenu = function() { 
  let overlay = document.querySelector('.overlay');
  let menuburger = document.querySelector('.hamburger');

  overlay.classList.add("open");
  menuburger.classList.add("is-active");
};
const closeNavMenu = function() { 
  let overlay = document.querySelector('.overlay');
  let menuburger = document.querySelector('.hamburger');

  overlay.classList.remove("open");
  menuburger.classList.remove("is-active");
};

class HeaderTemplate extends React.Component {
  render() {

    return (
      //
      <header className="naviga">      
        <TransitionLink
          className="navlogo"
          to="/"
          exit={{ length: 1, delay: .5 }}
          entry={{ delay: 1.5 }}
          >
          <ConfedLogoXSM />
        </TransitionLink>

        <ul class="largemenu">        
            <li>
              <TransitionLink
                to="/about/"
                exit={{ length: 1.5, delay: .5 }}
                entry={{ delay: 1 }}
                >
                  About
              </TransitionLink> 
            </li>
            <li>
              <TransitionLink
                to="/work/"
                exit={{ length: 1.5, delay: .5 }}
                entry={{ delay: 1 }}
                >
                  Work
              </TransitionLink>
            </li>
            <li>
              <TransitionLink
                to="/blog/"
                exit={{ length: 1.5, delay: .5 }}
                entry={{ delay: 1 }}
                >
                  Blog
              </TransitionLink>  
            </li>
        </ul>

        <div className="navbar-menu">
           <div onClick={openNavMenu} class="hamburger hamburger--minus">
            <div class="hamburger-box">
              <div class="hamburger-inner"></div>
            </div>
          </div>
        </div>

        <div className="overlay">
          <span className="nav-close" onClick={closeNavMenu}>Close Menu</span>

          <nav className="overlay-menu">
            <TransitionLink
                className="overlogo"
                to="/"
                exit={{ length: 1.5, delay: .5 }}
                entry={{ delay: 1 }}
                >
                  <ConfedLogoTY />
            </TransitionLink>
            <ul>
              <li>
                <TransitionLink
                  to="/about/"
                  exit={{ length: 1.5, delay: .5 }}
                  entry={{ delay: 1 }}
                  >
                    About
                </TransitionLink> 
              </li>
              <li>
                <TransitionLink
                  to="/work/"
                  exit={{ length: 1.5, delay: .5 }}
                  entry={{ delay: 1 }}
                  >
                    Work
                </TransitionLink>
              </li>
              <li>
                <TransitionLink
                  to="/blog/"
                  exit={{ length: 1.5, delay: .5 }}
                  entry={{ delay: 1 }}
                  >
                    Blog
                </TransitionLink>  
              </li>
            </ul>
          </nav>

          <h2 className="nav-contact">info@creativeconfederation.com</h2>

      </div>

        <div>
          <Media
            query="(min-width: 799px)"
            onChange={matches => {

                //// MINOR MINOR MINOR -- Not necessary for launch.
                //// MINOR MINOR MINOR 'bug'-- does not affect Experience negatively in React....
                /////// USE THIS TO CLOSE/HIDE THE OVERLAY MENU WHEN USERS ARE ON LARGER SCREENS
                /////// 1. User resizes browser with overlay open, from smol to beeg.
                /////// 2. User is on mobile device, opens menu in portrait view, rotates to landscape view

                // let boydboyd = document.querySelector('.cc-2019');
                // boydboyd.classList.toggle("yayaya");

                // if (overlay.classList.contains("open")) {
                    // Class exists!
                    // overlay.classList.remove("open");
                // } else {
                    // Class not found.
                // }

              }
            }
          />
        </div>

      </header>

    )
  }
}
export default HeaderTemplate
